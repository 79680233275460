import {
    Box,
    Flex,
    hasCloudinaryAsset,
    isCloudinaryVideo,
    hasYoutubeVideo,
    AspectRatio,
    useCldVideoPlayer,
    useTogglePlay,
} from "@project/ui";
import dynamic from "next/dynamic";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import type { ModuleVideoFieldsFragment } from "./__generated/module-video.contentful.generated";
import { ModuleSpacer } from "@/components/module-spacer";
import { Title } from "@/components/title";
import { Description } from "@/components/description";
import { useEffect, useMemo, useRef, useState } from "react";
import { useUsercentrics } from "@/components/user-centrics/usercentrics-provider";
import { porscheUsercentricsKnownProcessorNames } from "@/components/user-centrics/known-processor-names";
import { useRouter } from "next/router";
import { useAppStore } from "@/store/app-store";
import {
    PAGMSHEvents,
    sendPagDataToGTM,
    type GTMEvent,
    type PagDataComponentClickObject,
} from "@/lib/google-tag-manager/events";

const CldVideoPlayerProvider = dynamic(() =>
    import("@project/ui").then((mod) => mod.CldVideoPlayerProvider)
);
const CldVideo = dynamic(() => import("@project/ui").then((mod) => mod.CldVideo));
const CldVideoTogglePlay = dynamic(
    () => import("@project/ui").then((mod) => mod.CldVideoTogglePlay),
    { ssr: false }
);
const CldVideoScrubber = dynamic(() => import("@project/ui").then((mod) => mod.CldVideoScrubber));
const CldVideoToggleMute = dynamic(() =>
    import("@project/ui").then((mod) => mod.CldVideoToggleMute)
);
const CldVideoToggleFullscreen = dynamic(() =>
    import("@project/ui").then((mod) => mod.CldVideoToggleFullscreen)
);
const CldVideoTimeRemaining = dynamic(() =>
    import("@project/ui").then((mod) => mod.CldVideoTimeRemaining)
);

export type ModuleVideoProps = ModuleVideoFieldsFragment & {
    moduleIndex?: number | null;
};

type HandleTrackingProps = Omit<GTMEvent, "componentClick" | "pageExperience" | "context"> & {
    pageCategory: string;
    contentTags?: string[];
    moduleName: string;
    modulePosition: number;
    clickElementType: PagDataComponentClickObject["clickElementType"];
    clickElementId: PagDataComponentClickObject["clickElementId"];
    clickElementName: PagDataComponentClickObject["clickElementName"];
};

const handleTracking = ({
    eventAction,
    locale,
    pageCategory,
    contentTags = [],
    moduleName,
    modulePosition,
    clickElementType,
    clickElementId,
    clickElementName,
}: HandleTrackingProps): void => {
    sendPagDataToGTM({
        eventAction,
        locale,
        pageExperience: {
            pageCategory,
            contentTags,
        },
        context: {
            moduleName,
            modulePosition,
        },
        componentClick: {
            clickElementType,
            clickElementId,
            clickElementName,
        },
    });
};
type VideoControlsProps = {
    onClick?: (eventAction: (typeof PAGMSHEvents)[keyof typeof PAGMSHEvents]) => void;
};

const VideoControls = ({ onClick }: VideoControlsProps) => {
    const {
        state: { isPlaying },
    } = useCldVideoPlayer();

    const isFullscreenSupported = screenfull.isEnabled;

    return (
        <Flex
            onClick={useTogglePlay()}
            zIndex={1}
            flexDir={"column"}
            justifyContent={{ base: "flex-end", md: "space-between" }}
            position={"absolute"}
            w={"full"}
            h="full"
            transition="opacity 0.3s ease-in-out"
            opacity={1}
            transitionDelay="4s"
            sx={{
                ...(!isPlaying && {
                    opacity: 1,
                }),
                ...(isPlaying && {
                    opacity: 0,
                    transitionDelay: "4s",
                }),
                _hover: {
                    opacity: 1,
                    transitionDelay: "0s",
                },
            }}
        >
            {isFullscreenSupported && (
                <Flex
                    display={{ base: "none", md: "flex" }}
                    justifyContent={"flex-end"}
                    p={[4, 4, 10]}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <CldVideoToggleFullscreen />
                </Flex>
            )}
            <Flex
                alignItems="center"
                flexDir={"row"}
                gap={[3, 3, 4]}
                p={[4, 4, 10]}
                pos="relative"
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <CldVideoScrubber />
                <CldVideoTimeRemaining position="static" color="allWhite" />
                <CldVideoToggleMute
                    onClick={() => onClick?.(PAGMSHEvents.videoCldToggleMute_Click)}
                />
                <Box>
                    <CldVideoTogglePlay
                        onClick={() => onClick?.(PAGMSHEvents.videoCldTogglePlay_Click)}
                    />
                </Box>
            </Flex>
        </Flex>
    );
};

export const ModuleVideo = (props: ModuleVideoProps) => {
    const { mediaAsset, description, title, youtubeUrl, autoplay, moduleIndex, __typename } = props;

    const [youtubeIframeSrc, setYoutubeIframeSrc] = useState<string | null>(null);

    const youtubeWrappingContainerRef = useRef<HTMLDivElement>(null);

    const isYoutubeVideo = useMemo(() => {
        return hasYoutubeVideo(youtubeUrl);
    }, [youtubeUrl]);

    const { consentGiven, isLoaded, checkConsent } = useUsercentrics();

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        const consentGivenForYoutube = consentGiven.get(
            porscheUsercentricsKnownProcessorNames.youtubeVideo
        );

        if (consentGivenForYoutube?.consentStatus === true) {
            hasYoutubeVideo(youtubeUrl) && setYoutubeIframeSrc(youtubeUrl);
        } else {
            if (!youtubeWrappingContainerRef.current) {
                return;
            }

            setYoutubeIframeSrc(null);

            checkConsent({
                mapContainer: [youtubeWrappingContainerRef],
                processor: porscheUsercentricsKnownProcessorNames.youtubeVideo,
                successCallback: () => {
                    hasYoutubeVideo(youtubeUrl) && setYoutubeIframeSrc(youtubeUrl);
                },
            });
        }
    }, [youtubeUrl, isLoaded, consentGiven, checkConsent]);

    const { locale } = useRouter();
    const {
        state: { pageId, pageContentTags, pageType },
    } = useAppStore();

    const handleOnClickTracking = (
        eventActionProp: (typeof PAGMSHEvents)[keyof typeof PAGMSHEvents]
    ) => {
        handleTracking({
            eventAction: eventActionProp,
            locale: locale!,
            pageCategory: pageType,
            contentTags: pageContentTags,
            moduleName: __typename,
            modulePosition: moduleIndex ?? 0,
            clickElementType: "interaction",
            clickElementId: pageId,
            clickElementName: youtubeIframeSrc ?? title ?? "",
        });
    };

    return (
        <ModuleSpacer>
            {title && (
                <Box mb={{ base: 6, l: 8 }} color={"porscheBlack"} maxW="40rem">
                    <Title>{title}</Title>
                    {description && <Description mt={{ base: 4, l: 6 }}>{description}</Description>}
                </Box>
            )}
            <AspectRatio ratio={["9:16", "16:9"]}>
                {isYoutubeVideo ? (
                    <Box ref={youtubeWrappingContainerRef} borderRadius={12} bg="porscheBlack">
                        {youtubeIframeSrc && (
                            <ReactPlayer
                                url={youtubeIframeSrc}
                                controls={true}
                                width="100%"
                                height="100%"
                                onPlay={() =>
                                    handleOnClickTracking(PAGMSHEvents.videoIframePlay_Click)
                                }
                            />
                        )}
                    </Box>
                ) : (
                    <Box borderRadius={12}>
                        <CldVideoPlayerProvider
                            muted={autoplay ?? false}
                            autoplay={autoplay ?? false}
                        >
                            {hasCloudinaryAsset(mediaAsset) && isCloudinaryVideo(mediaAsset) && (
                                <>
                                    <CldVideo
                                        cloudinaryAsset={mediaAsset}
                                        wrapperProps={{
                                            position: "absolute",
                                        }}
                                        playsinline
                                        preload="auto"
                                        inViewAutoplay={autoplay}
                                    />
                                    <VideoControls onClick={handleOnClickTracking} />
                                </>
                            )}
                        </CldVideoPlayerProvider>
                    </Box>
                )}
            </AspectRatio>
        </ModuleSpacer>
    );
};
